
.Title {
    background: khaki;
  }
  
.Title2 {
font-size: 13px;
background: khaki;
}

.ArtTitle {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    /* height: 30px; */
    /* margin: "20px 0"; */
    background: khaki;
    /* font-style: italic; */
  }

.Description {
    font-size: 13px;
    white-space: pre-wrap;
}

.Description2 {
    font-size: 14px;
    white-space: pre-wrap;
}

.factText {
    background-color: #f1efef;
    font-size: 13px;
    white-space: pre-wrap;
  }